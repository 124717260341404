import { GlobalEventsService } from '../services/global-events.service';
import { createInitializerQueueList } from './initializer-manager';
import { SeoService } from '../services/seo.service';
import { EnvironmentProviders, inject, Injector, provideAppInitializer } from '@angular/core';
import { DecoratorService } from '../helpers/decorator-service.service';
import { InstallAppService } from '../services/install-app.service';
import { GoogleTagManagerService } from '../services/google-tag-manager.service';
import { ServiceWorkerService } from '../services/service-worker.service';
import { ZendeskChatService } from '../services/zendesk/zendesk-chat.service';
import { ThemeService } from '../services/theme.service';
import { AnimatedFaviconService } from '../services/animated-favicon.service';
import { CheckWebpService } from '../services/check-webp.service';
import { LotteryService } from '../services/lottery.service';
import { BankingService } from '../services/banking.service';
import { ArabicService } from '../services/arabic.service';
import { OneSignalService } from '../services/one-signal.service';
import { StagDetectorService } from '../services/stag-detector.service';
import { UserPhoneService } from '../services/user/user-phone.service';


/**
 * The `initApp` function initializes the DecoratorService with the provided Injector.
 */
export function initApp(injector: Injector) {
  DecoratorService.injector = injector;
}

export const provideInitializer = () => {
  return [
    provideAppInitializer(() => {
      const injector = inject(Injector);
      initApp(injector);
      return Promise.resolve();
    }),
    ...parallelInitializer,
  ];
};



const parallelInitializer: EnvironmentProviders[] = createInitializerQueueList([
  {
    token: ThemeService,
    initializerFn: (service: ThemeService) => service.resolveTheme$(),
  },
  {
    token: GlobalEventsService,
    initializerFn: (service: GlobalEventsService) => service.initGlobalEvent(),
  },
  {
    token: StagDetectorService,
    initializerFn: (service: StagDetectorService) => service.detectParams(),
  },
  {
    token: CheckWebpService,
    initializerFn: (service: CheckWebpService) => service.checkIfWebpSupported(),
  },
  {
    token: GoogleTagManagerService,
    initializerFn: (service: GoogleTagManagerService) => service.addGtmToDom(),
  },
  {
    token: ServiceWorkerService,
    initializerFn: (service: ServiceWorkerService) => service.registerServiceWorker(),
  },
  {
    token: SeoService,
    initializerFn: (service: SeoService) => service.updateGlobalHeadLink(),
  },
  {
    token: ZendeskChatService,
    initializerFn: (service: ZendeskChatService) => service.initChat(),
  },
  {
    token: InstallAppService,
    initializerFn: (service: InstallAppService) => service.resolveShowVariables(),
  },
  {
    token: LotteryService,
    initializerFn: (service: LotteryService) => service.resolveShowLottery(),
  },
  {
    token: BankingService,
    initializerFn: (service: BankingService) => service.getAllPayments(),
  },
  {
    token: ArabicService,
    initializerFn: (service: ArabicService) => service.resolveArabic(),
  },
  {
    token: OneSignalService,
    initializerFn: (service: OneSignalService) => service.handleId(),
  },
  {
    token: AnimatedFaviconService,
    initializerFn: (service: AnimatedFaviconService) => service.init(),
  },
  {
    token: UserPhoneService,
    initializerFn: (service: UserPhoneService) => service.updatePhoneStoreMask(),
  },
]);
