import { inject, provideAppInitializer } from '@angular/core';
import { Observable } from 'rxjs';

type QueueItem = {
  token: any,
  initializerFn: (service: any) => Promise<any> | Observable<any> | void
};

/**
 * Generates a list of `provideAppInitializer` based on the given queue items.
 * @param {QueueItem[]} queueItems - List of items to initialize.
 * @returns A list of providers for Angular's application initialization.
 */
export function createInitializerQueueList(queueItems: QueueItem[]) {
  return queueItems.map(item =>
    provideAppInitializer(() => {
      const service = inject(item.token);
      const result = item.initializerFn(service);
      if (result instanceof Observable) {
        return result.toPromise(); // Convert Observable to Promise for compatibility
      }
      return result || Promise.resolve(); // Ensure it always returns a Promise
    })
  );
}

