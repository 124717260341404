import { Component, inject } from '@angular/core';
import { DataPreloaderService } from './core/services/data-preloader.service';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterOutlet]
})
export class AppComponent {
  loader = inject(DataPreloaderService);

  title = 'Spin Samurai';
}
